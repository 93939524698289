import React, {useEffect} from 'react';
import Layout from '../components/Layout/Index';
import Header from "../components/Header";
import Footer from "../components/Footer";

import $ from 'jquery';

const style1 = {display: "none"};

const jQuery = () => {


//   $('.lb-btn').on('click', function(){

//     var src = $(this).attr('src');
//     $('.product_image').attr('src', src)
//     $('.centered-div').fadeIn();

//     var slide = $(this).attr('data-slide');
//     $('.centered-div img').attr('data-slide', slide);

//     $("body").keyup(function(e) {

//       var code = e.keyCode || e.which; //Find the key pressed
    
//             if (code == 39) 
//             {  
//               $('.right').click();
//             }
//             if(code == 37)
//             {
//               $('.left').click();
//             }
    
//     });

//   });


//   $('.close').on('click', function(){
//     $('.centered-div').fadeOut();
//   });


//   var slide = 1;

//   $('.lb-btn').each( function(){
//       $(this).attr("data-slide", slide++);
//   });


//   $('.right').on('click', function(){

//     var slide = $('.centered-div img').attr('data-slide');
    
//     var total_slides = $('.lb-btn').length;
//     if (slide < total_slides) {
//       slide++;
//     }

//     $('.centered-div img').attr('data-slide', slide);
//     var path = $('.lb-btn[data-slide = "' + slide + '"]').attr('src');
//     $('.centered-div img').attr('src', path);

//   });


//   $('.left').on('click', function(){

//     var slide = $('.centered-div img').attr('data-slide');

//     if (slide > 1) {
//       slide--;
//     }

//     $('.centered-div img').attr('data-slide', slide);
//     var path = $('.lb-btn[data-slide = "' + slide + '"]').attr('src');
//     $('.centered-div img').attr('src', path);

//   });


//   var native_width = 0;
//   var native_height = 0;

//   $(".magnifier-4").mousemove(function(e) {
//     if (!native_width && !native_height) {
//       var image_object = new Image();
//       image_object.src = $(".small").attr("src");
//       native_width = image_object.width;
//       native_height = image_object.height;
//     } else {
//       var magnify_offset = $(this).offset();
//       var mx = e.pageX - magnify_offset.left;
//       var my = e.pageY - magnify_offset.top;

//       if (mx < $(this).width() && my < $(this).height() && mx > 0 && my > 0) {
//         $(".large").fadeIn(100);
//       } else {
//         $(".large").fadeOut(100);
//       }
//       if ($(".large").is(":visible")) {
//         var rx =
//           Math.round(
//             mx / $(".small").width() * native_width - $(".large").width() / 2
//           ) * -1;
//         var ry =
//           Math.round(
//             my / $(".small").height() * native_height - $(".large").height() / 2
//           ) * -1;

//         var bgp = rx + "px " + ry + "px";

//         var px = mx - $(".large").width() / 2;
//         var py = my - $(".large").height() / 2;

//         var background_url = $('.product_image').attr('src');
//         var large_background = "url('"+background_url+"')";

//         if ($(window).width() < 767){
//           $(".large").css({ background: large_background, left: px, top: py, backgroundPosition: bgp, transform: "scale(0.4)", });
//         }
//         else {
//           $(".large").css({ background: large_background, left: px, top: py, backgroundPosition: bgp, transform: "scale(1.5)", });
//         }

//       }
//     }
//   });

//   $(".magnifier-3").mouseleave(function(){
//     $(".large").fadeTo("slow", 0);
//   });

//   $(".magnifier-3").mouseenter(function(){
//         $(".large").fadeTo("slow", 1);
//   });

//   $('#arm').bind('touchstart', function() {
//     $(".large").fadeTo("slow", 0);
//   });

//   $('#arm').bind('touchend', function() {
//     $(".large").fadeTo("slow", 1);
//   });

$(".dbl-sub-sec2").on("mouseenter", function () {
  $(this).find(".decor-tag-link").fadeIn();
});

$(".dbl-sub-sec2").on("mouseleave", function () {
  $(this).find(".decor-tag-link").fadeOut();
});

 }

const OurDesignStudios = () => {

  useEffect(() => {
   
    jQuery();
    
  }, []);

  useEffect(() => {

    // Update Page title tag  
    document.title = "Al Noor | Trending";

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "Trending decor collections of Al Noor...");

  }, []);

  return (
    <>
      <div style={{ boxShadow: "-7px 7px 45px 0px rgba(0, 0, 0, 0.1)" }}>
        <Header />
      </div>


      <p className='Submainhead' >Trending</p>


      <div className="dbl-1 d-flex align-items-center justify-content-center mt-4 trending-row">
          <div className="row">

            <div className="col-md-8 pl-1 pr-2 pt-2 pb-2 mt-2 mb-1">
              <img className="mt-1 mb-1 w-100 h-100 lb-btn" src="/images/trending-new/set-1/1.jpg"></img>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">

              <div className="row pl-3 trending-sub-row">

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                    <a target="_blank" href='/decorsdetail/4095'><img className="w-100 h-100" src="/images/trending-new/set-1/4095.jpg"></img></a>
                  </div>
                </div>

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                  <a target="_blank" href='/decorsdetail/2040-rust-ember'> <img className="w-100 h-100" src="/images/trending-new/set-1/set-1-b.jpg"></img></a>
                  </div>
                </div>

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                  <a target="_blank" href='/decorsdetail/s016-valongo-silver'><img className="w-100 h-100" src="/images/trending-new/set-1/set-1-c.jpg"></img></a>
                  </div>
                </div>
                
              </div>

            </div>

          </div>
      </div>

      

      <div className="dbl-1 d-flex align-items-center justify-content-center mt-4 trending-row">
          <div className="row">

            <div className="col-md-8 pl-1 pr-2 pt-2 pb-2 mt-2 mb-1">
              <img className="mt-1 mb-1 w-100 h-100 lb-btn" src="/images/trending-new/set-2/trending2.jpg"></img>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">

              <div className="row pl-3 trending-sub-row">

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                  <a target="_blank" href='/decorsdetail/1207-rocky-limestone'><img className="w-100 h-100" src="/images/trending-new/set-2/1207.jpg"></img></a>
                  </div>
                </div>

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                  <a target="_blank" href='/decorsdetail/2037-stone-grey'><img className="w-100 h-100" src="/images/trending-new/set-2/set-2-b.jpg"></img></a>
                  </div>
                </div>

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                  <a target="_blank" href='/decorsdetail/s017-valongo-slate'><img className="w-100 h-100" src="/images/trending-new/set-2/set-2-c.jpg"></img></a>
                  </div>
                </div>
                
              </div>

            </div>

          </div>
      </div>


        

      <div className="dbl-1 d-flex align-items-center justify-content-center mt-4 trending-row">
          <div className="row">

            <div className="col-md-8 pl-1 pr-2 pt-2 pb-2 mt-2 mb-1">
              <img className="mt-1 mb-1 w-100 h-100 lb-btn" src="/images/trending-new/set-3/trending-page-new-3.jpg"></img>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">

              <div className="row pl-3 trending-sub-row">

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                  <a target="_blank" href='/decorsdetail/4102-arora-elm'><img className="w-100 h-100" src="/images/trending-new/set-3/set-3-a.jpg"></img></a>
                  </div>
                </div>

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                  <a target="_blank" href='/decorsdetail/2038-concrete-grey'><img className="w-100 h-100" src="/images/trending-new/set-3/set-3-b.jpg"></img></a>
                  </div>
                </div>

                <div className="col-4 col-md-12 pt-2 pb-2 mt-2">
                  <div >
                  <a target="_blank" href='/decorsdetail/1015-hercley-oak'><img className="w-100 h-100" src="/images/trending-new/set-3/set-3-c.jpg"></img></a>
                  </div>
                </div>
                
              </div>

            </div>

          </div>
      </div>


        <br></br>
        <br></br>


      <div className="centered-div img-popop-dl" data-modal="dbl-02" style={style1}>

          <div className="close" data-modal="dbl-02">x</div>

          <div className='dds-control'>
            <i class="left fa-solid fa-angle-left"></i>
            <i class="right fa-solid fa-angle-right"></i>
          </div>

          <div class="magnifier-4 dbl-1 d-flex align-items-center justify-content-center" style={{overflow: "hidden", cursor: "crosshair"}}>
            <div class="large"></div>
            <img alt="img0" className="small img-fluid product_image" src="https://dummyimage.com/600x400/5c5c5c/bdbdbd.png" width="640"/>
          </div>

      </div>
      

      <Footer />
    </>
  )
}

export default OurDesignStudios