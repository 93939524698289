import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import $ from "jquery";

const style1 = { display: "none" };

const imageZoom = async () => {
  // $(".lb-btn").on("click", function () {
  //   var src = $(this).attr("src");
  //   $(".product_image").attr("src", src);
  //   $(".centered-div").fadeIn();
  // });

  // $(".close").on("click", function () {
  //   $(".centered-div").fadeOut();
  // });

  $(".fa-angle-left").on("click", function () {
    $(".swiper-button-prev").click();
  });

  $(".fa-angle-right").on("click", function () {
    $(".swiper-button-next").click();
  });

  $(".swiper-button-next, .swiper-button-prev").attr("style", "display:none");

  $("body").keyup(function (e) {
    var code = e.keyCode || e.which; //Find the key pressed

    if (code == 39) {
      $(".fa-angle-right").click();
    }
    if (code == 37) {
      $(".fa-angle-left").click();
    }
  });

  $(".dbl-sub-sec").on("mouseenter", function () {
    $(this).find(".decor-tag-link").fadeIn();
  });

  $(".dbl-sub-sec").on("mouseleave", function () {
    $(this).find(".decor-tag-link").fadeOut();
  });
};

const Homediaries = () => {
  useEffect(() => {
    imageZoom();
  }, []);

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={20}
      modules={[Navigation]}
      navigation={true}
      className="mySwiper nexthd prvhd"
    >
      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <center>
          <div className="container-fluid dbl-2 mt-3 mb-3">
            <div className="row padding-slides">
              <div className="col-12 col-md-12 pl-2 pr-2">
                <div className="h-100">
                  <img className="mt-1 w-100 h-100" src="/images/2020/1.png" />
                </div>
              </div>
            </div>
          </div>
        </center>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2  mb-3 custom-margin">
          <div className="row padding-slides">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <div className="h-100">
                <img className="mt-1 w-100 h-100" src="/images/2020/2.jpeg" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2  mb-3 custom-margin">
          <div className="row padding-slides">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <div className="h-100">
                <img className="mt-1 w-100 h-100" src="/images/2020/3.jpeg" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide className="d-flex align-items-center justify-content-center mt-4">
        <div className="container dbl-2  mb-3 custom-margin">
          <div className="row padding-slides">
            <div className="col-12 col-md-12 pl-2 pr-2">
              <div className="h-100">
                <img className="mt-1 w-100 h-100" src="/images/2020/4.jpeg" />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S001</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <a target="_blank" href="/decorsdetail/s001-canyon-jura-pine">
                <img className="mt-1 mb-1 w-100 h-100" src="images/2020/S001/3.jpg"></img>
                </a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/s001-canyon-jura-pine">
                      <img
                        className="w-100 h-100"
                        src="images/2020/S001/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/s001-canyon-jura-pine">
                      <img
                        className="w-100 h-100"
                        src="images/2020/S001/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S002</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="mt-1 w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2020/1.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/s002-noce-perenne">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/S002/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S005</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <video class=" w-100 h-100" controls autoplay>
                <source
                  src="/videos/catalogs/2020/2.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/s005-arabesque">
                      <img
                        className=" w-100 h-100"
                        src="images/2020/S005/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/s005-arabesque">
                      <img
                        className="w-100 h-100"
                        src="images/2020/S005/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S007</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <video class=" w-100 h-100" controls autoplay>
                <source
                  src="/videos/catalogs/2020/3.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/s007-canyon-painted-metal">
                      <img
                        className=" w-100 h-100"
                        src="images/2020/S007/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/s007-canyon-painted-metal">
                      <img
                        className="w-100 h-100"
                        src="images/2020/S007/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S008</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <a target="_blank" href="/decorsdetail/s008-bermuda">
                <img className="mt-1 mb-1 w-100 h-100" src="images/2020/S008/3.jpg"></img>
                </a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/s008-bermuda">
                      <img
                        className="w-100 h-100"
                        src="images/2020/S008/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/s008-bermuda">
                      <img
                        className="w-100 h-100"
                        src="images/2020/S008/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: S009</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <video class=" mb-1 w-100 h-100" controls autoplay>
                <source
                  src="/videos/catalogs/2020/4.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/s009-neapolis">
                      <img
                        className=" w-100 h-100"
                        src="images/2020/S009/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/s009-neapolis">
                      <img
                        className="w-100 h-100"
                        src="images/2020/S009/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 1059</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/1059">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/1059/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/1059">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/1059/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 1060</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/1060">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/1060/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/1060">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/1060/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 1061</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <video class=" w-100 h-100" controls autoplay>
                <source
                  src="/videos/catalogs/2020/5.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/1061">
                      <img
                        className=" w-100 h-100"
                        src="/images/2020/1061/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/106i">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/1061/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4082</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <video class="mt-1 w-100 h-100" controls autoplay>
                  <source
                    src="/videos/catalogs/2020/6.mp4"
                    type="video/mp4"
                  ></source>
                </video>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/4082">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/4082/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4083</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <a target="_blank" href="/decorsdetail/4083">
                <img
                  className="lb-btn mt-1 mb-1 w-100 h-100"
                  src="/images/2020/4083/11.jpg"
                ></img>
              </a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/4083">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/4083/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/4083">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/4083/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4084</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <a target="_blank" href="/decorsdetail/4084">
                <img
                  className="lb-btn mt-1 mb-1 w-100 h-100"
                  src="/images/2020/4084/11.jpg"
                ></img>
              </a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/4084">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/4084/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/4084">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/4084/1.jpg"
                      ></img>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4085</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <a target="_blank" href="/decorsdetail/4085">
                <img
                  className="lb-btn mt-1 mb-1 w-100 h-100"
                  src="/images/2020/4085/11.jpg"
                ></img>
              </a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/4085">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/4085/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/4085">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/4085/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4088</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/4088">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/4088/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/4088">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/4088/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4089</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/4089">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/4089/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/4089">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/4089/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 4090</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/4090">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/4090/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/4090">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/4090/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2028</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <video class=" w-100 h-100" controls autoplay>
                <source
                  src="/videos/catalogs/2020/7.mp4"
                  type="video/mp4"
                ></source>
              </video>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/2028-charcoal-grey">
                      <img
                        className=" w-100 h-100"
                        src="/images/2020/2028/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/2028-charcoal-grey">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/2028/1.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2029</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/2029-sage-green">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/2029/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/2029-sage-green">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/2029/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2030</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/2030-olive-green">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/2030/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/2030-olive-green">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/2030/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2031</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/2031-bottle-green">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/2031/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/2031-bottle-green">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/2031/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-2 mt-3 mb-3">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2032</p>
            <div className="col-sm-12 col-md-7 pl-2 pr-0">
              <div className="dbl-main-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/2032-ash-white">
                  <img
                    className="lb-btn mt-1 mb-2 w-100 h-100"
                    src="/images/2020/2032/11.jpg"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-5 col-md-5 pl-2 pr-0">
              <div className="dbl-sub-sec h-100 mt-2">
                <a target="_blank" href="/decorsdetail/2032-ash-white">
                  <img
                    className="mt-1 w-100 h-100"
                    src="/images/2020/2032/1.jpg"
                  ></img>
                
                <div className="decor-tag-link" style={{ display: "none" }}>
                  <div class="decor-tag">
                    <span class="dt-icon float-left">
                      <img src="/images/magnifying-glass-plus.png"></img>
                    </span>
                    <span class="dt-text float-right">View Decor</span>
                  </div>
                </div></a>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <SwiperSlide>
        <div className="container dbl-1 d-flex align-items-center justify-content-center mt-4">
          <div className="row padding-slides">
            <p className="m-0">Decor Code: 2033</p>
            <div className="col-md-8 pl-1 pr-1 mt-2 mb-1 dbl-main-sec">
              <a target="_blank" href="/decorsdetail/2033-rust">
                <img
                  className="lb-btn mt-1 mb-1 w-100 h-100"
                  src="/images/2020/2033/11.jpg"
                ></img>
              </a>
            </div>

            <div className="col-md-4 mt-1 mb-1 pl-2 pr-2">
              <div className="row">
                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-1">
                    <a target="_blank" href="/decorsdetail/2033-rust">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/2033/2.jpg"
                      ></img>
                    
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div></a>
                  </div>
                </div>

                <div className="col-6 col-md-12 pl-2 pr-2 mt-2 dbl-sub-sec">
                  <div className="dbl-sub-sec dbl-sub-sec-tab-2">
                    <a target="_blank" href="/decorsdetail/2033-rust">
                      <img
                        className="w-100 h-100"
                        src="/images/2020/2033/1.jpg"
                      ></img>
                    </a>
                    <div className="decor-tag-link" style={{ display: "none" }}>
                      <div class="decor-tag">
                        <span class="dt-icon float-left">
                          <img src="/images/magnifying-glass-plus.png"></img>
                        </span>
                        <span class="dt-text float-right">View Decor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>

      <div className="text-center mt-3 catalog">
        <p>
          <i class="fa-solid fa-angle-left"></i>

          <i class="fa-solid fa-angle-right"></i>
        </p>
      </div>
    </Swiper>
  );
};

export default Homediaries;
