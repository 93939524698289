import React, { useEffect } from "react";
import { FaApple } from "react-icons/fa";

import { DiAndroid } from "react-icons/di";
import { motion } from "framer-motion";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/pagination";

import Bookslider from "../components/bookslider";
import Homediaries from "../components/Homediaries";
import Homedi from "../components/homdi";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Cslider from "../components/curatedslider";


import $ from 'jquery';

const Homepage = () => {


  useEffect(() => {

    // Update Page title tag  
    document.title = "Al-Noor";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "With a focus on innovation and high-precision engineering, Al-Noor Lasani is the market leader and pioneer of decorative laminate surfaces in Pakistan. From home to commercial décor, from kitchens to furniture, our wide range of textures and finishes, and large colour palette has made us the premier choice for design and building professionals for over 30 years.");

  }, []);


  useEffect(() => {
    tdl();
    var colorIndex = 0;
    var colors = ["#fff", "green", "#dae305"];
    var color = document.getElementById("color");
    setInterval(function () {
      if (colorIndex >= colors.length) colorIndex = 0;
      color.style.color = colors[colorIndex];
      colorIndex++;
    }, 1500);
  }, []);

  const tdl = () => {
    (() => {
      const h1El = document.getElementById("tdl");
      const letters = h1El.textContent.split("");
      h1El.innerHTML = letters
        .map(
          (l, i) =>
            `<p style="--i: ${i}; --j: ${letters.length - 1 - i}">${
              l == " " ? "&nbsp;" : l
            }</p>`
        )
        .join("");

      h1El.addEventListener("mouseenter", () => {
        h1El.classList.add("flip-in");
        h1El.classList.remove("flip-out");
      });

      h1El.addEventListener("mouseleave", () => {
        h1El.classList.remove("flip-in");
        h1El.classList.add("flip-out");
      });
    })();
  };

  const spans = document.querySelectorAll(".word span");

  spans.forEach((span, idx) => {
    span.addEventListener("click", (e) => {
      e.target.classList.add("active");
    });
    span.addEventListener("animationend", (e) => {
      e.target.classList.remove("active");
    });

    // Initial animation
    setTimeout(() => {
      span.classList.add("active");
    }, 750 * (idx + 1));
  });
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };


  useEffect(() => {

    if ($(window).width() < 768) {

      $('.video-banner').find('img').removeClass('hero-banner-desktop');
      $('.video-banner').find('img').addClass('hero-banner-mobile');
      $('.video-banner').find('img').attr('src', '/images/homepage-banner-mobile.jpg');

    }

    if ($(window).width() < 768) {

      $('.moodboard-main').removeAttr('target');
      $('.moodboard-main').removeAttr('href');

    }

    // Update Page title tag  
    document.title = "Al-Noor";

    // Select the meta tag with a name attribute of "property"
    const socialPageTitle = document.querySelector('meta[property="og:title"]');

    // Update the content attribute of the meta tag
    socialPageTitle.setAttribute('content', "Al-Noor");

    // Select the meta tag with a name attribute of "description"
    const metaDescription = document.querySelector('meta[name="description"]');

    // Update the content attribute of the meta tag
    metaDescription.setAttribute('content', "With a focus on innovation and high-precision engineering, Al-Noor Lasani is the market leader and pioneer of decorative laminate surfaces in Pakistan. From home to commercial décor, from kitchens to furniture, our wide range of textures and finishes, and large colour palette has made us the premier choice for design and building professionals for over 30 years.");

  }, []);


  return (
    <div>
      <div
        style={{
          width: "100%",
          position: "sticky",
          zIndex: 90,
          top: "0px",
        }}
      >

        <Header />

      </div>
      <div className="video-banner" >
        <img width="100%" src="/images/homepage-banner.jpg"/>
        {/* <video className="hero-banner-desktop" height="100%" width="100%" loop autoPlay muted>
          <source src="/videos/homepage/banners.mp4" type="video/mp4" />
        </video> */}
        {/* <video className="hero-banner-mobile" height="100%" width="100%" loop autoPlay muted>
          <source src="/videos/homepage/banner-mob.mp4" type="video/mp4" />
        </video> */}
        {/* <div className="banner-text-wrapper">
          <div className="banner-text">
            Designs in Flux
            <div className="banner-braces" id="color">
              <i class="fa-solid fa-angle-right angle1"></i>
              <div></div>
              <i class="fa-solid fa-angle-right angle2"></i>
            </div>
          </div>
        </div> */}
      </div>

      

      <div
        style={{
          width: "100%",
        }}
      >
        <div className="Trenmaindiv">
          {/* <h5 className="Trenheading">Trending</h5> */}
          <a href="/trending-details">
            <motion.img
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.2 }}
              whileTap={{ scale: 0.99, borderRadius: "1%" }}
              className="img-fluid"
              width="100%"
              height="100%"
              src="/images/trending12.webp"
              alt="Alnoor"
              objectFit="cover"
            />
          </a>
        </div>
      </div>

      <div className="mbdiv">
        <div className="brandsdiv text-center">
          <div className="brandsdiv-1">
            <div className="brandsdiv-2">
              <img
                className="Trenimg img-fluid"
                src="/images/home-4.webp"
                alt="Alnoor"
                objectFit="cover"
              />
              <img
                className="Trenimg img-fluid home-sgloss"
                src="/images/home-5.webp"
                alt="Alnoor"
                objectFit="cover"
              />
              <img
                className="Trenimg img-fluid"
                src="/images/home-1.webp"
                alt="Alnoor"
                objectFit="cover"
              />
              <img
                className="Trenimg img-fluid"
                src="/images/home-3.webp"
                alt="Alnoor"
                objectFit="cover"
              />
              <img
                className="Trenimg img-fluid"
                src="/images/home-2.webp"
                alt="Alnoor"
                objectFit="cover"
              />
              <img
                className="Trenimg img-fluid"
                src="/images/geodesic-lg.webp"
                alt="Alnoor"
                objectFit="cover"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section 3 */}

      <div className="boutmd">
        <div>
          <p className="boutp">
            With a focus on innovation and high-precision engineering, Al-Noor
            Lasani is the market leader and pioneer of decorative laminate
            surfaces in Pakistan.
          </p>
          <p className="boutp">
            From home to commercial décor, from kitchens to furniture, our wide
            range of textures and finishes, and large colour palette has made us
            the premier choice for design and building professionals for over 30
            years.
          </p>
          <p className="boutp">
            We provide design solutions to adapt to your space, and your
            individuality. From contemporary mirror-finish-glossy, earthy matte,
            to artistic tactile, our surfaces bring your ideas to life. Our
            proprietary S Gloss, Tactile, Natural Matte, High gloss and Select
            series products are manufactured and distributed under the Al-Noor
            Lasani umbrella brand.
          </p>
        </div>
      </div>

      {/* <div className="select-sec" style={{ width: "100%", marginTop: "43px" }}>
        <div className="Selectmd">
          <motion.img
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.5 }}
            whileTap={{ scale: 1.0 }}
            width="236px"
            height="108px"
            src="/images/select.webp"
            alt="Alnoor"
            objectFit="cover"
          />
          <p className="text-center Selectmd-text">Select from Al-Noor Lasani is the latest range of carefully curated avant-garde design trends from Europe. Harmonized with our exclusive textures, the Select range puts emphasis on innovative features such as striking growth lines and natural figures with attractive 3D effects reproducing authentic finishes.</p>
        </div> 
        <img
          className="img-fluid"
          src="/images/selbg.webp"
          alt="Alnoor"
          objectFit="cover"
        />
      </div> */}

<div className="container-fluid-gdc">

<center>

  <div className="row w-100 p-1 mt-5" style={{overflow: "hidden", }}>

    <div className="col-md-6 geo-col">
    <a href="/geodesic" target="_blank">
      <div class="geodesic-center" style={{ background: "url('/images/Homepage4.jpg')" }} >
        <video loop autoPlay muted>
          <source src="/videos/gla4.mp4" type="video/mp4" />
        </video>
      </div>
    </a>
    </div>

    <div className="col-md-4 sci-col">

      <div className="row">

        <div className="col-md-12">
          <a href="/scintilla" target="_blank">
            <div class="" style={{  overflow: "hidden" , marginBottom: "20px"}} >
              <video style={{}} height="100%" width="100%" loop autoPlay muted>
                <source src="/videos/scintilla-banner.mp4" type="video/mp4" />
              </video>
            </div>
          </a>
          
        </div> 

        <div className="col-md-12">
        <div
            class="select-center"
            style={{ background: "url('/images/select-banner-new.jpg')"}}
          >
            <a href="/decors/?filter=select" target="_blank">
              <img className="select-img" src="/images/select.webp" />
            </a>
          </div>
        </div>

      </div>

    </div>

  </div>

</center>

</div>

{/* Section 5 */}

      <div className="mb-center">

      <div className="row container-mdbrd w-100 m-0" style={{overflow: "hidden", paddingBottom: "40px"}}>

        <div className="col-md-8 moodboard">

          <div className="text-center  mt-5">
          
            <h6 className="moodheading">Mood Board</h6>

            <a className="moodboard-main" href="https://alnoormdf.com/moodboard/" target="_blank">
              <img
                
                src="/images/tablet.webp"
                alt="Alnoor"
                objectFit="contain"
                className="moodboard-home"

              />
            </a>

            <div className="text-center">
              <a style={{color:"black"}} href="https://apps.apple.com/my/app/al-noor/id1606951018?platform=ipad" target="_blank">
              <FaApple
                className="appicon"
                style={{
                  margin: "10px",
                }}
              /></a>
              <a style={{color:"black"}} href="https://play.google.com/store/apps/details?id=com.al_noor.moodboards" target="_blank">
              <DiAndroid
                className="appicon"
                style={{
                  margin: "10px",
                }}
              /></a>
            </div>

          </div>

        </div>

        <div className="col-md-4" style={{ position: "relative" }}>
          
          <Bookslider />

          <div className="animated-pointer-home">

            <motion.div
              style={{
                border: "2px solid #474b4e",
                borderRadius: "10px",
                padding: "6px",
                width: "19px",
                height: "38px",
              }}
            >
              <motion.div
                style={{
                  width: "3px",
                  height: "3px",
                  borderRadius: "10px",
                  backgroundColor: "#474b4e",
                }}
                animate={{
                  y: [
                    0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 20, 18, 16, 14, 12,
                    10, 8, 6, 4, 2, 0,
                  ],
                  x: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                  ],
                }}
                transition={{ duration: 2.5, yoyo: Infinity }}
              ></motion.div>
            </motion.div>

          </div>

        </div>

        

      </div>

      </div>

      {/* Section 6 */}

      <motion.div className="hdmdeone">
        <motion.div
          className="hdmdetwo"
          animate={{
            y: [
              0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 20, 18, 16, 14, 12, 10, 8,
              6, 4, 2, 0,
            ],
            x: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0, 0, 0, 0, 0, 0, 0, 0,
            ],
          }}
          transition={{ duration: 2.5, yoyo: Infinity }}
        ></motion.div>
      </motion.div>

      <div style={{ width: "100%", marginTop: "94px", zIndex: 80 }}>
        <h6 className="homediariesh">Design Diaries</h6>

        {/* <Homediaries /> */}
        <Homedi />
      </div>

      {/* Section 7 */}

      <div className="tactmd">
        <div className="tactimgdiv">
          <img
            width="100%"
            height="100%"
            src="/images/tactilelog.webp"
            alt="Alnoor"
            objectFit="contain"
          />
        </div>
        <div className="tactpdiv">
          {" "}
          <p className="tactp">
            Tactile is part of Al Noor's commitment to push boundaries in decor
            trends through innovative design, textures.
          </p>
        </div>
        <div className="tactbandiv">
          {" "}
          <a href="/decors/?filter=tactile" target="_blank">
            <img
              className="tactbanimg"
              width="100%"
              height="100%"
              src="/images/tacban.webp"
              alt="Alnoor"
              objectFit="cover"
            />
          </a>
        </div>
      </div>

      {/* Section 8 */}

      <a href="/decors" target="_blank">

      <div class="bg-dark text-white decor-library-home">

            <div class="d-flex h-100">
                
                <div class="justify-content-center align-self-center text-center w-100 dl-text-wrap">
                  
                
                <div className="texthov" onMouseOver={tdl}>
                  <div id="tdl">The Decors Library</div>

                  <span className="decolibhone1">T</span>
                  <span className="decolibhone2">h</span>
                  <span className="decolibhone3">e</span>

                  <span className="decolibhone4">D</span>
                  <span className="decolibhone5">e</span>
                  <span className="decolibhone6">c</span>
                  <span className="decolibhone7">o</span>
                  <span className="decolibhone8">r</span>
                  <span className="decolibhone9">s</span>

                  <span className="decolibhone10">L</span>
                  <span className="decolibhone11">i</span>
                  <span className="decolibhone12">b</span>
                  <span className="decolibhone13">r</span>
                  <span className="decolibhone14">a</span>
                  <span className="decolibhone15">r</span>
                  <span className="decolibhone16">y</span>
                  <p className="decolibhtwo-1"> A spectrum of hundreds of decors</p>
                </div>
                
                </div>

            </div>

        </div>
        
        </a>

      {/* <a href="/decors">
        <motion.div className="decolibdiv">
          <div className="texthov" onMouseOver={tdl}>
            <div id="tdl">The Decors Library</div>

            <span className="decolibhone1">T</span>
            <span className="decolibhone2">h</span>
            <span className="decolibhone3">e</span>

            <span className="decolibhone4">D</span>
            <span className="decolibhone5">e</span>
            <span className="decolibhone6">c</span>
            <span className="decolibhone7">o</span>
            <span className="decolibhone8">r</span>
            <span className="decolibhone9">s</span>

            <span className="decolibhone10">L</span>
            <span className="decolibhone11">i</span>
            <span className="decolibhone12">b</span>
            <span className="decolibhone13">r</span>
            <span className="decolibhone14">a</span>
            <span className="decolibhone15">r</span>
            <span className="decolibhone16">y</span>
            <p className="decolibhtwo"> A spectrum of hundreds of decors</p>
          </div>

          <img
            className="decolibimg"
            src="/images/decorslib.webp"
            alt="Alnoor"
          />
        </motion.div>
      </a> */}

      {/* Section 9 */}

      <div className="Curatedmd" style={{ width: "100%" }}>
        <h6 className="Curatedheading">Curated Collection</h6>
        <div className="desktop-view">
          <Cslider />
        </div>
      </div>

      <div id="demo" class="carousel slide mobile-view" data-ride="carousel">
        <ul class="carousel-indicators d-none">
          <li data-target="#demo" data-slide-to="0" class=""></li>
          <li data-target="#demo" data-slide-to="1" class="active"></li>
          <li data-target="#demo" data-slide-to="2"></li>
        </ul>

        <div class="container carousel-inner no-padding">
          <div class="carousel-item active carousel-item-left">
            <div class="col-sm-12">
              <img src="/images/c_1.webp"></img>
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/4093" target="_blank">
                <img src="/images/c_2.webp"></img>
              </a>
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/1013" target="_blank">
                <img src="/images/c_3.webp"></img>
              </a>
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/dec1201orsdetail/" target="_blank">
                <img src="/images/c_4.webp"></img>
              </a>
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/1206" target="_blank">
                <img src="/images/c_5.webp"></img>
              </a>{" "}
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/4095" target="_blank">
                <img src="/images/c_6.webp"></img>
              </a>
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/s008-bermuda" target="_blank">
                <img src="/images/c_7.webp"></img>
              </a>{" "}
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/s009-neapolis" target="_blank">
                <img src="/images/c_8.webp"></img>
              </a>{" "}
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/s011-pure" target="_blank">
                <img src="/images/c_9.webp"></img>
              </a>{" "}
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/s012-nero-marquina" target="_blank">
                <img src="/images/c_10.webp"></img>
              </a>
            </div>
          </div>

          <div class="carousel-item carousel-item-next carousel-item-left">
            <div class="col-sm-12">
              <a href="/decorsdetail/s013-flagstaff-oak" target="_blank">
                <img src="/images/c_11.webp"></img>
              </a>
            </div>
          </div>
        </div>

        <a class="carousel-control-prev" href="#demo" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#demo" data-slide="next">
          <span class="carousel-control-next-icon"></span>
        </a>
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
