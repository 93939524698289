import { React, useEffect } from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

import ReactBootstrap from "react-bootstrap";
import { Button } from "react-bootstrap";
import { ButtonGroup } from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import { MenuItem } from "react-bootstrap";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import { Container, Nav, Navbar, Dropdown, Stack } from "react-bootstrap";

import $ from 'jquery';
import { click } from "@testing-library/user-event/dist/click";


const Header = () => {



  document.addEventListener('DOMContentLoaded', function () {
    const headerNavbar = document.querySelector('.headernavbar');

    window.addEventListener('scroll', function () {
      if (window.scrollY > 50) { // Adjust 50 to the scroll threshold you want
        headerNavbar.classList.add('scrolled');
      } else {
        headerNavbar.classList.remove('scrolled');
      }
    });
  });



  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div>

      <div id="overlay"></div>

      {/* <section class="sidebar">

		<div class="logo sticky-top"><a href="#" class="logo mb-5"></a><img class='img-fluid w-50' src="/images/logo.webp" /></div>

		<div class="menu primary-menu">

			<div class="menu-heading">General:</div>

			<ul class="nav navbar-nav pl-3 mt-2">

			<li class="active"><a href="#"><i class="fa fa-house"></i> Plots</a></li>
			<li><a href="#"><i class="fa fa-users"></i> Members</a></li>

			</ul>

		</div>

		
		
	</section> */}

      {/* <div style={{width: "100%", display: "flex", alignTtems: "center", justifyContent: "center", marginBottom: "-50px", zIndex: "500", position: "absolute", left: "0", top: "0"}}>
        <a style={{zIndex: "2000"}} href="/scintilla" target="_blank">
        <img class="scintilla-widget" src="/images/Scintilla-Click.webp"></img>
        </a>
      </div> */}

      <div className="headerbody" />

      <Navbar
        collapseOnSelect
        className="headernavbar"
      >
        <Navbar.Brand href="/">
          <div className="mainlogomd">

            <img
              className="mainlogo"
              src="/images/logo.webp"
              alt="Alnoor"
              objectFit="cover"
            />

          </div>
        </Navbar.Brand>




        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" style={{ height: "135px" }}>
          <Nav
            className="me-auto navbody"

          >
            <Nav.Link href="/decors">
              {" "}
              <div className="navlink navlink2">
                {/* <div
                  className="navround"
                /> */}
                <h5 className="navtext">Decor Library</h5>
              </div>
            </Nav.Link>
            <Nav.Link href="/design-diaries">
              {" "}
              <div className="navlink">
                {/* <div
                 className="navround"
                 
                /> */}
                <h5 className="navtext">Design Diaries</h5>
              </div>
            </Nav.Link>
            <Nav.Link href="/trending-details">
              {" "}
              <div className="navlink">
                {/* <div
                  className="navround"
                /> */}
                <h5 className="navtext">Trending</h5>
              </div>
            </Nav.Link>
            <Nav.Link href="/decor-books">
              {" "}
              <div className="navlink">
                {/* <div
                  className="navround"
                /> */}
                <h5 className="navtext">Collections</h5>
              </div>
            </Nav.Link>
            <Nav.Link href="/our-design-studios">
              {" "}
              <div className="navlink">
                {/* <div
                  className="navround"
                /> */}
                <h5 className="navtext">Design Studios</h5>
              </div>
            </Nav.Link>



          </Nav>




          <Nav className="thirdhead">
            {/* <img className="navdropdownimg nav-mobile-show sidebar-icon menu-button"
                      width="29px"
                      height="15px"
                      src="/images/menu.webp"
                      alt="Alnoor"
                      objectFit="cover"
                    /> */}
            <Stack>

              <div className="navstack"
              >
                <Dropdown>
                  <Dropdown.Toggle className="navdropdown"
                    style={{
                      backgroundColor: "transparent",
                      border: "0px solid transparent",
                    }}
                    id="dropdown-basic"
                  >
                    <img className="navdropdownimg"
                      width="29px"
                      height="15px"
                      src="/images/menu.webp"
                      alt="Alnoor"
                      objectFit="cover"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ width: "100%" }} className="ddmenu shadow">

                    <div style={{ zIndex: "5000" }}>
                      <div class="nav-mobile-show logo sticky-top text-center"><a href="/" class="logo mb-5"><img class='img-fluid w-50' src="/images/logo.webp"></img></a></div>
                      {/* <span className="nav-mobile-show h5 mt-3 mb-3 ml-3">Menu:</span> */}
                      <Dropdown.Item className="dditem" href="/decors">Décor Library</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/design-diaries">Design Diaries</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/trending-details">Trending</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/decor-books">Collections</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/geodesic">Geodesic</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/scintilla">Scintilla</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/dealers">Our Dealerships</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/our-design-studios">Design Studios</Dropdown.Item>
                      {/* <Dropdown.Item className="dditem" href="/action-3">Mood Board</Dropdown.Item> */}
                      <Dropdown.Item className="dditem" href="/about">About Us</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/contact-us">Contact Us</Dropdown.Item>
                      <Dropdown.Item className="dditem" href="/technical-specifications">Technical Specifications</Dropdown.Item>
                    </div>
                    <div></div>
                  </Dropdown.Menu>

                </Dropdown>

                    
                      
                {/* <div >
   
      <DropdownButton
        key='start'
        id={`dropdown-button-drop`}
        drop='start'
      >  
        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="3">Something else here</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
      </DropdownButton>
   
  </div> */}

                {/* <Nav.Link href="#deets">
                  {" "}
                  <div
                    style={{
                      height: "15px",
                      width: "29px",
                      marginLeft: "auto",
                      marginRight: "9%",
                      
                    }}
                  >
                    <img
                      width="29px"
                      height="15px"
                      src="/images/menu.webp"
                      alt="Alnoor"
                      objectFit="cover"
                     
                    />
                  </div>

                  
                </Nav.Link> */}
                <div></div>
              </div>

              <div className="bottomdiv"
              >
                <FaInstagram
                  onClick={() =>
                    openInNewTab("https://www.instagram.com/alnoorlasani/")
                  }
                  // style={{ width: "18px", height: "18px", marginTop: "2px" }}
                  className="SocialiconsI"
                />
                <FaFacebookF
                  onClick={() =>
                    openInNewTab(
                      "https://web.facebook.com/AlNoorLasaniMDF?_rdc=1&_rdr"
                    )
                  }
                  // style={{ width: "10px", height: "20px", marginLeft: "18px" }}
                  className="SocialiconsF"

                />
              </div>
            </Stack>
          </Nav>





        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
